'use client';

import React from 'react';
import {
  getDevelopmentFlagOverrides,
  setDevelopmentFlagsCookie,
} from '@mentimeter/development-flags';
import { DevelopmentToolsContext } from './DevelopmentToolsContext';

export const DevelopmentToolsProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [overrideValues, setOverrideValues] = React.useState(
    getDevelopmentFlagOverrides(),
  );

  return (
    <DevelopmentToolsContext.Provider
      value={{
        overrides: {
          values: overrideValues,
          set(key, value) {
            setOverrideValues((prev) => {
              const newOverrides = { ...prev, [key]: value };
              setDevelopmentFlagsCookie(newOverrides);
              return newOverrides;
            });
          },
          reset(key) {
            setOverrideValues((prev) => {
              const { [key]: _, ...remainingOverrides } = prev;
              setDevelopmentFlagsCookie(remainingOverrides);
              return remainingOverrides;
            });
          },
          clearAll() {
            setDevelopmentFlagsCookie({});
            setOverrideValues({});
          },
        },
        isModalOpen,
        openModal() {
          setModalOpen(true);
        },
        closeModal() {
          setModalOpen(false);
        },
      }}
    >
      {children}
    </DevelopmentToolsContext.Provider>
  );
};
