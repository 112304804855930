import * as React from 'react';
import type { ClickableT } from '../Clickable';
import { Clickable } from '../Clickable';

export interface ClickableIconT extends ClickableT {
  children: React.ReactNode;
  disabled?: boolean | undefined;
}

/**
 * @deprecated Use `IconButton` instead
 *
 */
const ClickableIcon = ({
  children,
  disabled,
  extend,
  ...rest
}: ClickableIconT) => {
  return (
    <Clickable
      extend={({ theme }) => ({
        transition: `all ${theme.durations[1]}s ease`,
        '@media (hover: hover)': {
          ':hover': {
            transform: disabled ? undefined : 'scale(1.2)',
          },
        },
        ':active': {
          transform: disabled ? undefined : 'scale(0.9)',
        },
        ...(extend ? extend({ theme }) : {}),
      })}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Clickable>
  );
};

export { ClickableIcon };
