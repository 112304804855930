'use client';

import type { ReactNode } from 'react';
import * as React from 'react';
import { experiments } from '@mentimeter/splitio';
import { GROUPS } from '../hooks/experiment-groups';
import { DevelopmentToolsSetup } from './DevelopmentToolsSetup';

export function WwwDevelopmentToolsSetup({
  isAuthorizedToInteractWithDevelopmentTools,
  children,
}: {
  isAuthorizedToInteractWithDevelopmentTools: boolean;
  children: ReactNode;
}) {
  return (
    <DevelopmentToolsSetup
      experiments={experiments}
      isAuthorizedToInteractWithDevelopmentTools={
        isAuthorizedToInteractWithDevelopmentTools
      }
      experimentGroups={GROUPS}
    >
      {children}
    </DevelopmentToolsSetup>
  );
}
