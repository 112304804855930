import * as React from 'react';
import { StringParser } from '@mentimeter/string-parser';
import type { SimpleInlineNodesT } from '@mentimeter/ragnar-markdown';
import type { JSX } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../../Box';
import { ErrorMessage } from '../../ErrorMessage';
import {
  type ValidatableT,
  runValidators,
  VALIDATORS,
} from '../../utils/_Validators';
import { MarkdownMenu } from '../../utils/_MarkdownMenu';
import FocusableCharacterCount from '../../utils/_FocusableCharacterCount';
import { type TextInputItemT, TextInputItem } from './TextInputItem';
import { TextInputIcon } from './TextInputIcon';

export interface TextInputT extends TextInputItemT, ValidatableT {
  maxLength?: number;
  defaultValue?: string | undefined;
  displayMarkdownMenu?: boolean | undefined;
  mdMenuOptions?: Array<SimpleInlineNodesT>;
  iconLeading?: JSX.Element | undefined | null;
  iconLeadingAction?: () => void;
  iconLeadingLabel?: string;
  iconTrailing?: JSX.Element;
  iconTrailingAction?: () => void;
  iconTrailingLabel?: string;
  trackMarkdown?: (type: string) => void;
}
export const TextInput = React.forwardRef<HTMLInputElement, TextInputT>(
  (
    {
      defaultValue,
      maxLength,
      onChange,
      placeholder,
      value = '',
      validators = [],
      displayMarkdownMenu = false,
      mdMenuOptions,
      onValidation,
      onFocus: _of,
      onBlur: _ob,
      iconLeading,
      iconTrailing,
      iconLeadingAction,
      iconTrailingAction,
      iconLeadingLabel,
      iconTrailingLabel,
      trackMarkdown,
      id,
      inputSize = 'default',
      'aria-describedby': ariaDescribedby,
      mb,
      mt,
      m,
      ml,
      mr,
      mx,
      my,
      className,
      p,
      py,
      pr,
      pl,
      pb,
      pt,
      px,
      width,
      height,
      ...rest
    },
    forwardedRef,
  ) => {
    const containerStyling = {
      mb,
      mt,
      m,
      ml,
      mr,
      mx,
      my,
      width,
      height,
      pb,
      pt,
      p,
      pl,
      pr,
      px,
      py,
    };

    const [currentValue, setCurrentValue] = React.useState(String(value));
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const [elementId, setElementId] = React.useState<string>('');
    const [hardCapped, setHardCapped] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      if (!id) {
        setElementId(`input-${Math.floor(Math.random() * 10000000000)}`);
      } else {
        setElementId(id);
      }
    }, [id]);

    // use forwardedRef if given, otherwise inputRef
    const reference =
      forwardedRef !== undefined && forwardedRef !== null
        ? forwardedRef
        : inputRef;

    // dynamically adjust maxLength so markdown "doesn't count"
    const stringParserRef = React.useRef<HTMLDivElement | null>(null);
    const [maxLengthAdjusted, setMaxLengthAdjusted] = React.useState(
      maxLength || null,
    );
    React.useEffect(() => {
      // maxLength should only need to be adjusted if md menu is enabled
      if (
        maxLength &&
        displayMarkdownMenu &&
        stringParserRef.current &&
        stringParserRef.current.innerText
      ) {
        const valueAfterParse = stringParserRef.current.innerText.trim();
        setMaxLengthAdjusted(
          maxLength + currentValue.length - valueAfterParse.length,
        );
      } else {
        setMaxLengthAdjusted(maxLength || null);
      }
    }, [stringParserRef, currentValue, maxLength, displayMarkdownMenu]);

    const defaultValidators = React.useMemo(
      () =>
        maxLengthAdjusted ? [VALIDATORS.maxLength(maxLengthAdjusted)] : [],
      [maxLengthAdjusted],
    );

    const validate = React.useCallback(
      (value: string) => {
        const allValidators = [...defaultValidators, ...validators];
        return runValidators(allValidators, (validators) => {
          setHardCapped(Boolean(validators['maxLength']));
          if (onValidation) onValidation(validators);
        })(value);
      },
      [onValidation, validators, defaultValidators],
    );

    React.useEffect(() => {
      setCurrentValue(String(value));
    }, [value]);

    React.useEffect(() => {
      validate(currentValue);
    }, [currentValue, validate]);

    // menti-style placeholders
    const handleFocus = React.useCallback(
      (focused: boolean) => {
        setIsFocused(focused);
        if (focused && defaultValue === value) {
          setCurrentValue('');
        } else {
          setCurrentValue(String(value));
        }
      },
      [setCurrentValue, defaultValue, value],
    );

    const handleUpdate = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setCurrentValue(newValue);
        if (onChange) onChange(event);
      },
      [onChange],
    );

    const inputItemClasses = cn(
      iconLeading && 'pl-8',
      iconLeading && inputSize === 'default' && 'pl-10',
      (iconTrailing || maxLength) && 'pr-8',
      (iconTrailing || maxLength) && inputSize === 'default' && 'pr-10',
      iconTrailing && maxLength && 'pr-14',
      defaultValue === value ? 'text-weaker' : undefined,
    );

    return (
      <Box
        {...containerStyling}
        className={cn('w-full', className)}
        data-testid="text-input-container"
      >
        <FocusableCharacterCount
          id={`${elementId}-character-counter`}
          iconTrailing={Boolean(iconTrailing)}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          maxLength={maxLengthAdjusted ?? undefined}
          value={currentValue}
          render={({ value, onBlur, onFocus }) => (
            <Box className="relative w-full">
              {iconLeading && (
                <TextInputIcon
                  icon={iconLeading}
                  iconLabel={iconLeadingLabel}
                  iconAction={iconLeadingAction}
                  inputSize={inputSize}
                  iconType="leading"
                />
              )}
              <TextInputItem // rest needs to be before onChange to not overwrite it
                {...rest}
                id={elementId}
                ref={reference}
                aria-describedby={`${
                  ariaDescribedby ? ariaDescribedby : ''
                } ${elementId}-character-counter max-length-${elementId}`}
                placeholder={defaultValue || placeholder}
                onFocus={(e) => {
                  onFocus(e);
                  if (_of) _of(e); // <Input />'s onFocus
                }}
                onBlur={(e) => {
                  onBlur(e);
                  if (_ob) _ob(e); // <Input />'s onBlur
                }}
                maxLength={maxLengthAdjusted ?? undefined}
                onChange={handleUpdate}
                value={value}
                inputSize={inputSize}
                className={inputItemClasses}
              />
              {iconTrailing && (
                <TextInputIcon
                  icon={iconTrailing}
                  iconLabel={iconTrailingLabel}
                  iconAction={iconTrailingAction}
                  inputSize={inputSize}
                  iconType="trailing"
                />
              )}

              {/*render parsed text to be able to get actual text length (not visible to user)*/}
              <Box ref={stringParserRef} className="hidden">
                <StringParser
                  source={value}
                  disableLineBreaks
                  allowHyperlinks
                />
              </Box>

              {displayMarkdownMenu && isFocused && (
                <MarkdownMenu
                  forwardedRef={reference}
                  options={mdMenuOptions}
                  text={value}
                  onUpdate={handleUpdate}
                  track={trackMarkdown}
                />
              )}
            </Box>
          )}
        />
        {isFocused && hardCapped && (
          <ErrorMessage className="mt-2" id={`max-length-${elementId}`}>
            You have reached the maximum length
          </ErrorMessage>
        )}
      </Box>
    );
  },
);
