import { TextInput } from '@mentimeter/ragnar-ui';
import { CrossIcon, SearchIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';

interface SearchProps {
  value: string;
  onSearch: (searchString: string) => void;
  onClear: () => void;
}

export function Search({ value, onSearch, onClear }: SearchProps) {
  return (
    <TextInput
      inputSize="compact"
      autoFocus
      width="100%"
      iconLeading={<SearchIcon />}
      iconTrailing={<CrossIcon />}
      iconTrailingAction={onClear}
      autoComplete="off"
      placeholder="Type to search"
      value={value}
      onChange={(e) => onSearch(e.target.value)}
    />
  );
}
