import React from 'react';
import { Box, Text, Badge } from '@mentimeter/ragnar-ui';
import { Heading } from './Heading';

export function FlagSection({
  children,
  id,
  title,
  variant = 'default',
}: {
  children: React.ReactNode;
  id: string;
  title: string;
  variant?: 'development' | 'experiment' | 'default';
}) {
  return (
    <Box width="100%" data-testid={`flag-section-${id}`}>
      <Heading>
        <Box flexDirection="row" alignItems="baseline">
          <Text color="text" fontWeight="semiBold" pb={2}>
            {title}
          </Text>
          {variant === 'experiment' && (
            <Badge ml="space1" compact type="text" text="Split" />
          )}
          {variant === 'development' && (
            <Badge
              ml="space1"
              compact
              type="text"
              color="onNoticeWeak"
              bg="noticeWeak"
              text="Development"
            />
          )}
        </Box>
      </Heading>
      {children}
    </Box>
  );
}

export function FlagList({ children }: { children: React.ReactNode }) {
  return <Box width="100%">{children}</Box>;
}
