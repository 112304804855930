import React from 'react';
import {
  DEVELOPMENT_FLAGS,
  isValidDevelopmentFlagKey,
  type DevelopmentFlagKey,
} from '@mentimeter/development-flags';
import { sortFlags } from '../utils';
import { DEFAULT_VALUE } from '../constants';
import type { FlagT } from '../types';
import { DevelopmentToolsContext } from '../context';

export function useDevelopmentFlags({
  searchString,
}: {
  searchString: string;
}) {
  const developmentToolsContext = React.useContext(DevelopmentToolsContext);

  const developmentFlags = Object.keys(DEVELOPMENT_FLAGS)
    .filter(isValidDevelopmentFlagKey)
    .map(fromDevelopmentFlag)
    .sort(sortFlags)
    .filter((flag) =>
      flag.displayName.toLowerCase().includes(searchString.toLowerCase()),
    );

  const activeDevelopmentFlags = developmentFlags.filter((flag) => flag.active);

  return {
    developmentFlags,
    activeDevelopmentFlags,
    setDevelopmentFlag,
    resetDevelopmentFlag,
    resetAllDevelopmentFlags,
  };

  function setDevelopmentFlag(flagName: string, value: string) {
    developmentToolsContext.overrides.set(flagName, value);
  }

  function resetDevelopmentFlag(flagName: string) {
    developmentToolsContext.overrides.reset(flagName);
  }

  function resetAllDevelopmentFlags() {
    developmentToolsContext.overrides.clearAll();
  }

  function fromDevelopmentFlag(flagKey: DevelopmentFlagKey): FlagT {
    const developmentFlag = DEVELOPMENT_FLAGS[flagKey];
    const flagValue = developmentToolsContext.overrides.values[flagKey];

    return {
      key: flagKey,
      type: 'development',
      displayName: developmentFlag.displayName,
      description: developmentFlag.description,
      options: developmentFlag.values,
      value: flagValue ?? DEFAULT_VALUE,
      active: flagValue !== undefined,
    };
  }
}
