import React from 'react';
import { Box, Text, Clickable } from '@mentimeter/ragnar-ui';
import type { FlagGroupElement } from '../../types';

interface FlagGroupsProps {
  experimentFlagGroups: FlagGroupElement[];
  onGroupClick: (flags: FlagGroupElement) => void;
}

export function FlagGroups({
  experimentFlagGroups,
  onGroupClick,
}: FlagGroupsProps) {
  return (
    <Box width="100%" flexDirection="row" flexWrap="wrap">
      {experimentFlagGroups.map((group) => (
        <FlagGroupItem
          key={group.id}
          active={group.active}
          onClick={() => {
            onGroupClick(group);
          }}
        >
          {group.name}
        </FlagGroupItem>
      ))}
    </Box>
  );
}

function FlagGroupItem({
  active,
  onClick,
  children,
}: {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <Clickable
      bg={active ? 'brand' : 'neutralWeak'}
      px={3}
      py={1}
      m={1}
      borderRadius={3}
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
    >
      <Text
        fontWeight="semiBold"
        fontSize="75"
        color={active ? 'onBrand' : 'onNeutralWeak'}
      >
        {children}
      </Text>
    </Clickable>
  );
}
