'use client';

import * as React from 'react';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';

export function SplitFactoryProviderWrapper({
  config,
  children,
}: {
  config: SplitIO.IBrowserSettings;
  children: React.ReactNode;
}) {
  return (
    <SplitFactoryProvider config={config} updateOnSdkTimedout>
      {children}
    </SplitFactoryProvider>
  );
}
