import React from 'react';
import { Button } from '@mentimeter/ragnar-ui';
import { CheckIcon, CopyIcon } from '@mentimeter/ragnar-visuals';
import { FEEDBACK_DURATION } from '../../constants';
import type { FlagT } from '../../types';

interface CopyButtonProps {
  activeFlags: FlagT[];
  disabled?: boolean;
}

export function CopyButton({ activeFlags, disabled = false }: CopyButtonProps) {
  const [copied, setCopied] = React.useState(false);

  return (
    <Button
      disabled={disabled}
      variant={copied ? 'positive' : 'tertiary'}
      size="compact"
      iconLeading={copied ? CheckIcon : CopyIcon}
      onClick={() => {
        navigator.clipboard.writeText(JSON.stringify(activeFlags));
        setCopied(true);
        setTimeout(() => setCopied(false), FEEDBACK_DURATION);
      }}
    >
      {copied ? 'Copied' : 'Copy to clipboard'}
    </Button>
  );
}
