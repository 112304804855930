'use client';

import React, { useContext } from 'react';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import { useRouter } from '@mentimeter/next-navigation';
import type { ExperimentsSetup } from '@mentimeter/splitio';
import { useExperimentFlags } from '../hooks';
import type { FlagGroup } from '../types';
import { useDevelopmentFlags } from '../hooks/useDevelopmentFlags';
import { DevelopmentToolsContext } from '../context';
import { FlagGroups } from './components/FlagGroups';
import { CopyButton } from './components/Copy';
import { ImportButton } from './components/Import';
import { Bubble } from './components/Bubble';
import { Notice } from './components/Notice';
import { Search } from './components/Search';
import { Modal } from './components/Modal';
import { FlagList, FlagSection } from './components/FlagSection';
import { Flag } from './components/Flag';

export function DevelopmentToolsModal({
  experiments,
  experimentGroups = [],
}: {
  experiments: ExperimentsSetup;
  experimentGroups: FlagGroup[];
}) {
  const router = useRouter();
  const developmentToolsContext = useContext(DevelopmentToolsContext);
  const [searchString, setSearchString] = React.useState('');
  const {
    experimentFlags,
    activeExperimentFlags,
    experimentFlagGroups,
    resetAllExperiments,
    setExperimentOverride,
    resetExperimentOverride,
    toggleExperimentGroup,
  } = useExperimentFlags({
    experiments,
    experimentGroups,
    searchString,
  });
  const {
    developmentFlags,
    activeDevelopmentFlags,
    setDevelopmentFlag,
    resetDevelopmentFlag,
    resetAllDevelopmentFlags,
  } = useDevelopmentFlags({ searchString });

  const activeFlagsCount = activeExperimentFlags.concat(
    activeDevelopmentFlags,
  ).length;
  const flagHits = experimentFlags.concat(developmentFlags).length;

  return (
    <>
      {activeFlagsCount > 0 && (
        <Bubble
          onClick={developmentToolsContext.openModal}
          overriddenExperimentsCount={activeFlagsCount}
        />
      )}

      <Modal
        open={developmentToolsContext.isModalOpen}
        onClose={() => {
          developmentToolsContext.closeModal();
        }}
      >
        <Box display="grid" gridAutoRows="auto" gap={3}>
          <Notice />

          <Search
            value={searchString}
            onSearch={(searchString) => setSearchString(searchString)}
            onClear={() => setSearchString('')}
          />

          <Box
            mb="space3"
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            gap={2}
          >
            <ImportButton
              clearAll={resetAllExperiments}
              flags={[...experimentFlags, ...developmentFlags]}
              onImportSuccess={(flags) => {
                setSearchString('');

                flags.forEach((flag) => {
                  if (!flag.value) return;

                  if (flag.type === 'experiment') {
                    setExperimentOverride(flag.key, flag.value);
                  } else if (flag.type === 'development') {
                    setDevelopmentFlag(flag.key, flag.value);
                  }
                });

                refreshFlags();
              }}
            />

            <CopyButton
              disabled={activeFlagsCount < 1}
              activeFlags={[
                ...activeExperimentFlags,
                ...activeDevelopmentFlags,
              ]}
            />

            <Button
              size="compact"
              variant="outline"
              onClick={() => {
                resetAllExperiments();
                resetAllDevelopmentFlags();
                refreshFlags();
              }}
              disabled={activeFlagsCount < 1}
            >
              Reset all flags
            </Button>
          </Box>

          {experimentFlagGroups && experimentFlagGroups.length > 0 && (
            <FlagSection id="groups" title="Groups">
              <FlagGroups
                experimentFlagGroups={experimentFlagGroups}
                onGroupClick={(group) => {
                  toggleExperimentGroup(group);
                  refreshFlags();
                }}
              />
            </FlagSection>
          )}

          {flagHits === 0 && <Text color="textWeaker">No results</Text>}

          {activeFlagsCount > 0 && (
            <FlagSection id="enabled" title={`Enabled (${activeFlagsCount})`}>
              <FlagList>
                {activeExperimentFlags.map((flag) => (
                  <Flag
                    key={flag.key}
                    flag={flag}
                    onChange={(value) => {
                      setExperimentOverride(flag.key, value);
                      refreshFlags();
                    }}
                    onReset={() => {
                      resetExperimentOverride(flag.key);
                      refreshFlags();
                    }}
                  />
                ))}
                {activeDevelopmentFlags.map((flag) => (
                  <Flag
                    variant="development"
                    key={flag.key}
                    flag={flag}
                    onChange={(value) => {
                      setDevelopmentFlag(flag.key, value);
                      refreshFlags();
                    }}
                    onReset={() => {
                      resetDevelopmentFlag(flag.key);
                      refreshFlags();
                    }}
                  />
                ))}
              </FlagList>
            </FlagSection>
          )}

          {experimentFlags.length > 0 && (
            <FlagSection
              id="experimental-flags"
              title="Experimental flags"
              variant="experiment"
            >
              <FlagList>
                {experimentFlags.map((flag) => (
                  <Flag
                    key={flag.key}
                    flag={flag}
                    onChange={(value) => {
                      setExperimentOverride(flag.key, value);
                      refreshFlags();
                    }}
                    onReset={() => {
                      resetExperimentOverride(flag.key);
                      refreshFlags();
                    }}
                  />
                ))}
              </FlagList>
            </FlagSection>
          )}
          {developmentFlags.length > 0 && (
            <FlagSection
              id="development-flags"
              title="Development flags"
              variant="development"
            >
              <FlagList>
                {developmentFlags.map((flag) => (
                  <Flag
                    variant="development"
                    key={flag.key}
                    flag={flag}
                    onChange={(value) => {
                      setDevelopmentFlag(flag.key, value);
                      refreshFlags();
                    }}
                    onReset={() => {
                      resetDevelopmentFlag(flag.key);
                      refreshFlags();
                    }}
                  />
                ))}
              </FlagList>
            </FlagSection>
          )}
        </Box>
      </Modal>
    </>
  );

  function refreshFlags() {
    // Refresh the app to hydrate server-side experiments.
    if (globalThis.__mentimeterRuntime === 'app') {
      router.refresh();
    }
  }
}
