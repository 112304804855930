import React from 'react';
import { Text } from '@mentimeter/ragnar-ui';

export function Notice() {
  return (
    <>
      <Text fontSize={1} fontWeight="bold" color="notice">
        WARNING: EXPERIMENTAL FEATURES
      </Text>
      <Text fontSize={1} color="textWeaker">
        By enabling these flags, you could make the product unstable.
        Experimental features might change suddenly or not work as expected.
        Enabled features apply to all users of this browser&apos;s session.
      </Text>
    </>
  );
}
