'use client';
import * as React from 'react';
import { type ReactNode } from 'react';
import { useHasMounted } from '@mentimeter/react-hooks';
import type { ExperimentsSetup } from '@mentimeter/splitio';
import { DevelopmentToolsModal } from '../modal';
import { DevelopmentToolsProvider } from '../context';
import type { FlagGroup } from '../types';

export function DevelopmentToolsSetup({
  isAuthorizedToInteractWithDevelopmentTools,
  experiments,
  experimentGroups = [],
  children,
}: {
  isAuthorizedToInteractWithDevelopmentTools: boolean;
  experiments: ExperimentsSetup;
  experimentGroups?: FlagGroup[];
  children: ReactNode;
}) {
  const hasMounted = useHasMounted();

  if (!isAuthorizedToInteractWithDevelopmentTools) return children;

  return (
    <DevelopmentToolsProvider>
      {children}
      {hasMounted && (
        <DevelopmentToolsModal
          experiments={experiments}
          experimentGroups={experimentGroups}
        />
      )}
    </DevelopmentToolsProvider>
  );
}
