import { type ExperimentsSetup } from '@mentimeter/splitio';
import { useSplitOverrides } from '@mentimeter/splitio';
import { sortFlags } from '../utils';
import type { FlagGroup, FlagGroupElement, FlagT } from '../types';
import { DEFAULT_VALUE } from '../constants';

export function useExperimentFlags({
  experiments,
  experimentGroups,
  searchString,
}: {
  experiments: ExperimentsSetup;
  experimentGroups: FlagGroup[];
  searchString: string;
}) {
  const overrides = useSplitOverrides();

  const flags = Object.keys(experiments)
    .filter((experimentName) =>
      experimentName.toLowerCase().includes(searchString.toLowerCase()),
    )
    .map(fromExperiment)
    .sort(sortFlags);

  const groups = experimentGroups
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map((group) => ({
      ...group,
      active: groupIsEnabled(group),
    }));

  return {
    experimentFlags: flags,
    activeExperimentFlags: flags.filter((flag) => flag.active),
    inactiveExperimentFlags: flags.filter((flag) => !flag.active),
    experimentFlagGroups: groups,
    resetAllExperiments() {
      overrides.clearAll();
    },
    setExperimentOverride: (key: string, value: string) => {
      overrides.set(key, value);
    },
    resetExperimentOverride: (key: string) => {
      overrides.reset(key);
    },
    toggleExperimentGroup: (group: FlagGroupElement) => {
      if (group.active) {
        for (const [key] of Object.entries(group.flags)) {
          overrides.reset(key);
        }
      } else {
        for (const [key, value] of Object.entries(group.flags)) {
          if (value) {
            overrides.set(key, value);
          }
        }
      }
    },
  };

  function groupIsEnabled(group: FlagGroup) {
    return Object.keys(group.flags).every(
      (flag) => overrides.values[flag] === group.flags[flag],
    );
  }

  function fromExperiment(experimentName: string): FlagT {
    const treatments = experiments[experimentName] as string[] | undefined;
    const value = overrides.values[experimentName] ?? DEFAULT_VALUE;
    return {
      key: experimentName,
      displayName: experimentName,
      type: 'experiment',
      options: treatments,
      value,
      active: value !== undefined,
    };
  }
}
