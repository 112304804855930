import Cookies from '@mentimeter/cookies';
import { DEVELOPMENT_FLAGS_KEY } from './constants';

export function setDevelopmentFlagsCookie(overrides: Record<string, string>) {
  Cookies.setJSON({
    name: DEVELOPMENT_FLAGS_KEY,
    type: 'functional',
    value: overrides,
  });
}
